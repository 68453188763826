<template>
  <div class="storeNav">
    <div :class="['item', item.status ? 'selected' : '']" v-for="(item, index) in storeMenu" :key="index"
      @click="storeType((index + 1), item.title, item.status)">
      <img :src="require('@/assets/storeTypes/' + item.icon)" />
      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storeMenu: [
        { title: '美食', icon: 'jiuba.png', status: false },
        { title: '电影演出', icon: 'jsyd.png', status: false },
        { title: '休闲玩乐', icon: 'KTV.png', status: false },
        { title: '酒店民宿', icon: 'ms.png', status: false },
        { title: '生活服务', icon: 'spa.png', status: false }
      ]
    }
  },
  created() {
    const classify = this.$route.query.classify || 0
    this.menuList(classify)
  },
  methods: {
    menuList(classify, status) {
      const list = this.storeMenu
      list.map((v, i) => {
        if (Number(classify) === (i + 1)) {
          list[i].status = !status
        } else {
          list[i].status = false
        }
      })
    },
    storeType(classify, type, status) {
      this.menuList(classify, status)
      if (status) this.$router.push({ path: '/stores' })
      else this.$router.push({ path: '/stores', query: { classify: classify, type: type } })
    }
  }
}
</script>