<template>
    <div class="container">
        <navbar :title="title" :to="goBack" />
        <storeNav></storeNav>
        <div class="body store">
            <div class="storeList" style="overflow:auto" v-infinite-scroll="load">
                <div infinite-scroll-disabled="disabled" style="overflow:auto;">
                    <div class="storeItem" v-for="(store, index) in storeList" :key="index">
                        <img src="@/assets/shangjia.jpg" />
                        <div class="storeInfo">
                            <h3>{{ store.username }}</h3>
                            <p><i class="el-icon-mobile-phone"></i>{{ store.phone }}</p>
                            <p><i class="el-icon-office-building"></i>{{ store.storeLocal }}</p>
                            <div class="discount">
                                第一类人才:{{ store.discount.firstCategory }}折
                                第二类人才:{{ store.discount.secondCategory }}折<br>
                                第三类人才:{{ store.discount.thirdCategory }}折
                                第四类人才:{{ store.discount.fourthCategory }}折<br>
                                第五类人才:{{ store.discount.fifthCategory }}折
                                第六类人才:{{ store.discount.sixthCategory }}折<br>
                            </div>
                        </div>
                    </div>
                    <div class="more" v-if="max">加载中...</div>
                    <div class="more" v-if="!max && storeList.length > 0">没有更多了</div>
                    <el-empty v-if="!max && storeList.length === 0"></el-empty>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '@/api/request'
export default {
    data() {
        return {
            type: 0,
            storeList: [],
            page: 1,
            pageSize: 5,
            max: true
        }
    },
    watch: {
        $route(to, from) {
            this.show()
        }
    },
    created() {
        this.type = this.$route.query.classify || 0
        this.title = this.$route.query.type || '优惠商家'
        // this.load()
    },
    computed: {
        noMore() {
            return this.max
        },
        disabled() {
            return this.noMore
        }
    },
    methods: {
        show() {
            this.page = 1
            this.max = true
            this.type = this.$route.query.classify || 0
            this.title = this.$route.query.type || '优惠商家'
            this.storeList = []
            // this.load()
        },
        load() {
            if (!this.max) {
                return
            }
            axios.get('/store/getStorePage?page=' + this.page + '&pageSize=' + this.pageSize + '&classify=' + this.type).then(resp => {
                this.page++
                var list = resp.data.data.records
                if (list.length == 0) {
                    this.max = false
                }
                for (let i = 0; i < list.length; i++) {
                    this.storeList.push(list[i])
                }
                this.storeList.push()
            })
        },
        goBack() {
            this.$router.push('/')
        }
    }
}
</script>
<style scoped>

</style>