<template>
    <div class="container">
        <navbar title="人才码申领" />
        <div class="body">
            <div class="subtitle">申请人信息</div>
            <el-form :model="form" label-width="100px" :rules="rules" ref="form">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="国籍" prop="country" class="radioGroup">
                    <div @click="pushCountry">
                        <el-radio v-model="radioCountry" label="1">中国</el-radio>
                    </div>
                    <div @click="clearCountry">
                        <el-radio v-model="radioCountry" label="2">其他</el-radio>
                    </div>
                    <el-input v-model="form.country" style="width:55VW" v-if="radioCountry == 2" placeholder="请输入国籍">
                    </el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-select v-model="form.sex" placeholder="请选择" style="width:55VW">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="民族" prop="nationality" class="radioGroup">
                    <div @click="pushNationality">
                        <el-radio v-model="radioNationality" label="1">汉族</el-radio>
                    </div>
                    <div @click="clearNationality">
                        <el-radio v-model="radioNationality" label="2">其他</el-radio>
                    </div>
                    <el-input v-model="form.nationality" style="width:55VW" v-if="radioNationality == 2"
                        placeholder="请输入民族">
                    </el-input>
                </el-form-item>
                <el-form-item label="出生日期" prop="bornDate">
                    <el-col>
                        <el-date-picker type="date" placeholder="选择日期" v-model="form.bornDate" style="width:55VW"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="政治面貌" prop="politicalStatus">
                    <el-select v-model="form.politicalStatus" placeholder="请选择" style="width:55VW">
                        <el-option label="党员" value="1"></el-option>
                        <el-option label="共青团员" value="2"></el-option>
                        <el-option label="群众" value="3"></el-option>
                        <el-option label="无党派人士" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号" prop="num">
                    <el-input v-model="form.num" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="学历" prop="education">
                    <el-select v-model="form.education" placeholder="请选择" style="width:55VW">
                        <!-- <el-option label="小学" value="1"></el-option>
                    <el-option label="初中" value="2"></el-option>
                    <el-option label="中专" value="3"></el-option>
                    <el-option label="高中" value="4"></el-option> -->
                        <el-option label="大专" value="5"></el-option>
                        <el-option label="本科" value="6"></el-option>
                        <el-option label="硕士研究生" value="7"></el-option>
                        <el-option label="博士研究生" value="8"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="毕业院校" prop="school">
                    <el-input v-model="form.school" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="毕业专业" prop="specialty">
                    <el-input v-model="form.specialty" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="mail">
                    <el-input v-model="form.mail" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="工作单位" prop="company">
                    <el-input v-model="form.company" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="工作职务" prop="position">
                    <el-input v-model="form.position" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="来赣前单位" prop="formerCompany">
                    <el-input v-model="form.formerCompany" style="width:55VW"></el-input>
                </el-form-item>
                <el-form-item label="来赣前职务" prop="formerPosition">
                    <el-input v-model="form.formerPosition" style="width:55VW" placeholder="请输入来赣前单位职务"></el-input>
                </el-form-item>
                <el-form-item label="职称" prop="jobTitle">
                    <el-select v-model="form.jobTitle" placeholder="请选择" style="width:55VW">
                        <el-option label="中级职称" value="1"></el-option>
                        <el-option label="副高职称" value="2"></el-option>
                        <el-option label="正高职称" value="3"></el-option>
                        <el-option label="暂无" value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人才类别" prop="category">
                    <el-select v-model="form.category" placeholder="请选择" style="width:55VW">
                        <el-option label="第一类" value="1"></el-option>
                        <el-option label="第二类" value="2"></el-option>
                        <el-option label="第三类" value="3"></el-option>
                        <el-option label="第四类" value="4"></el-option>
                        <el-option label="第五类" value="5"></el-option>
                        <el-option label="第六类" value="6"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="所获荣誉(如有多项荣誉请分行填写)" prop="honor">
                <el-input v-model="form.honor" style="width:55VW;height: 20vw;"></el-input>
            </el-form-item> -->
                <el-popover placement="bottom" title="提示：" width="200" trigger="click" content="如有多项荣誉请分行填写。">
                    <!-- <el-button slot="reference">click 激活</el-button> -->
                    <el-form-item label="所获荣誉" prop="honor" slot="reference">
                        <el-input type="textarea" rows="5" v-model="form.honor" style="width:55VW;"></el-input>
                    </el-form-item>
                </el-popover>
                <el-form-item label="引进方式" prop="introductionMethod">
                    <el-select v-model="form.introductionMethod" placeholder="请选择" style="width:55VW">
                        <el-option label="柔性引进" value="1"></el-option>
                        <el-option label="刚性引进" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="引进时间（年月）" prop="introductionDate">
                <el-col>
                    <el-date-picker v-model="form.introductionDate" type="month" placeholder="选择月"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
            </el-form-item> -->
                <el-form-item label="行业类别" prop="industryCategory">
                    <!-- <el-input v-model="form.industryCategory" style="width:55VW"></el-input> -->
                    <el-select v-model="form.industryCategory" style="width:55VW" clearable placeholder="请选择">
                        <el-option v-for="item in optionsIndustryCategory" :key="item.industryCategory"
                            :label="item.label" :value="item.industryCategory">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="phone">
                    <el-input v-model="form.phone" style="width:55VW" type="hidden"></el-input>
                </el-form-item>
                <el-divider content-position="left">资料上传（基础人才可不上传）</el-divider>

                <div class="subtitle">证明图片</div>

                <el-upload class="avatar-uploader" ref="otherLicense" action :auto-upload="false"
                    accept="image/jpeg,image/png,image/jpg" :on-preview="handlePicPreview" :on-remove="handleRemove"
                    :file-list="fileList" :on-change="otherSectionFile" list-type="picture-card" multiple>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" class="avatar">
                </el-dialog>

                <!-- <span style="line-height: 40px;margin-left: 10px;">学历证书</span>

            <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                style="margin: 10px;">
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog> -->

                <!-- <span style="line-height: 40px;margin-left: 10px;">个税社保及其他</span>

            <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                style="margin: 10px;">
                <i class="el-icon-plus"></i>
            </el-upload> -->
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>

                <el-checkbox v-model="form.checked" style="margin: 30px;">本人承诺上述信息填写真实有效</el-checkbox>
                <el-form-item>
                    <el-button type="primary" @click="submit">保存并提交</el-button>
                    <el-button @click="toIndex">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import axios from "@/api/request";
const options = {
    data() {
        return {
            optionsIndustryCategory: [{
                industryCategory: '1',
                label: '农林牧渔业'
            }, {
                industryCategory: '2',
                label: '采矿业'
            }, {
                industryCategory: '3',
                label: '制造业'
            }, {
                industryCategory: '4',
                label: '电力热力燃气及水生产和供应业'
            }, {
                industryCategory: '5',
                label: '建筑业'
            }, {
                industryCategory: '6',
                label: '批发和零售业'
            }, {
                industryCategory: '7',
                label: '交通运输仓储和邮政业'
            }, {
                industryCategory: '8',
                label: '住宿和餐饮'
            }, {
                industryCategory: '9',
                label: '信息传输、软件和信息技术服务业'
            }, {
                industryCategory: '10',
                label: '金融业'
            }, {
                industryCategory: '11',
                label: '房地产业'
            }, {
                industryCategory: '12',
                label: '租赁和商务服务业'
            }, {
                industryCategory: '13',
                label: '科学研究和技术服务业'
            }, {
                industryCategory: '14',
                label: '水利、环境和公共设施管理业'
            }, {
                industryCategory: '15',
                label: '居民服务、修理和其他服务业'
            }, {
                industryCategory: '16',
                label: '教育'
            }, {
                industryCategory: '17',
                label: '卫生和社会工作'
            }, {
                industryCategory: '18',
                label: '文化、体育和娱乐业'
            }, {
                industryCategory: '19',
                label: '公共管理、社会保障和社会组织'
            }, {
                industryCategory: '20',
                label: '国际组织'
            }
            ],
            radioCountry: '1',
            radioNationality: '1',
            rules: {
                num: [
                    { required: true, message: "请输入身份证号", trigger: 'blur' },
                    {
                        validator: function (rule, value, callback) {
                            if (/(^\d{15}$)|(^\d{17}(\d|X)$)/.test(value) == false) {
                                callback(new Error("请输入正确的身份证号"));
                            } else {
                                //校验通过
                                callback();
                            }
                        }, trigger: 'blur',
                    }
                ],
                mail: [
                    { required: true, message: "请输入邮箱", trigger: 'blur' },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(value) == false) {
                                callback(new Error("请输入正确的邮箱"));
                            } else {
                                //校验通过
                                callback();
                            }
                        }, trigger: 'blur',
                    }
                ],
                name: [{ required: true, message: "请输入姓名", trigger: 'blur' }],
                sex: [{ required: true, message: "请输入性别", trigger: 'blur' }],
                nationality: [{ required: true, message: "请输入民族", trigger: 'blur' }],
                bornDate: [{ required: true, message: "请输入出生日期", trigger: 'blur' }],
                country: [{ required: true, message: "请输入国籍", trigger: 'blur' }],
                politicalStatus: [{ required: true, message: "请输入政治面貌", trigger: 'blur' }],
                education: [{ required: true, message: "请输入学历", trigger: 'blur' }],
                school: [{ required: true, message: "请输入毕业院校", trigger: 'blur' }],
                specialty: [{ required: true, message: "请输入毕业专业", trigger: 'blur' }],
                company: [{ required: true, message: "请输入工作单位", trigger: 'blur' }],
                position: [{ required: true, message: "请输入工作单位职务", trigger: 'blur' }],
                formerCompany: [{ required: true, message: "请输入来赣前单位", trigger: 'blur' }],
                formerPosition: [{ required: true, message: "请输入来赣前单位职务", trigger: 'blur' }],
                jobTitle: [{ required: true, message: "请输入职称", trigger: 'blur' }],
                category: [{ required: true, message: "请输入人才类别", trigger: 'blur' }],
                honor: [{ required: true, message: "请输入所获荣誉", trigger: 'blur' }],
                introductionMethod: [{ required: true, message: "请输入引进方式", trigger: 'blur' }],
                // introductionDate: [{ required: true, message: "请输入引进时间（年月）", trigger: 'blur' }],
                industryCategory: [{ required: true, message: "请输入行业类别", trigger: 'blur' }],
            },
            form: {
                name: '',
                sex: '',
                nationality: '汉族',
                bornDate: '',
                country: '中国',
                politicalStatus: '',
                num: '',
                education: '',
                school: '',
                specialty: '',
                phone: '',
                mail: '',
                company: '',
                position: '',
                formerCompany: '',
                formerPosition: '',
                jobTitle: '',
                category: '',
                honor: '',
                pic: '',
                introductionMethod: '',
                // introductionDate: '',
                industryCategory: '',
                checked: false,
            },
            fileList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            fileDelUrlMap: new Map(),
        }
    },
    methods: {
        pushCountry() {
            this.form.country = '中国'
        },
        pushNationality() {
            this.form.nationality = '汉族'
        },
        clearCountry() {
            this.form.country = ''
        },
        clearNationality() {
            this.form.nationality = ''
        },
        //图片上传
        handlePicPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleRemove(file, fileList) {
            this.fileList.map((item, index) => {
                if (item.uid == file.uid) {
                    this.fileList.splice(index, 1)
                }
            })
            var url = this.fileDelUrlMap.get(file.raw.uid)
            this.$axios.get(url)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        otherSectionFile(file) {
            const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
            const isJPG = typeArr.indexOf(file.raw.type) !== -1;
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isJPG) {
                this.$message.error('只能是图片!');
                this.$refs.upload.clearFiles();
                this.otherFiles = null;
                return;
            }
            if (!isLt3M) {
                this.$message.error('上传图片大小不能超过 3MB!');
                this.$refs.upload.clearFiles();
                this.otherFiles = null;
                return;
            }
            this.otherFiles = file.raw;
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access_token': 'bT4vhYOyshOvX7QWsZU35v:Qp1tKsyEZtUQIwcYf1ZIlF339jKMNYt:1',
                    'accessKeyId': 'AqPWaKKKVkKTCMW6xM0azM',
                    'accessKeySecret': '9lZe2J1aeqIMJFUwV0QRjNTSmi2n7bb'
                },
                methods: 'post'
            }
            this.$axios.post("/api1/ajax/bucket/create", {
                file: this.otherFiles,
                bucket: 'bu1',
                filePath: '/zzb'
            }, config).then(res => {
                this.fileList.push(file)
                if (res.status == 200) {
                    this.form.pic = this.form.pic + '@' + res.data.data[0].url
                } else {
                    this.$message.error(res.data.statusText)
                }
            })
        },
        toIndex() {
            this.$router.push('/');
        },
        submit() {
            if (!this.checkForm()) {
                this.$message.error('请完善表单相关信息！');
                return;
            }
            axios.post('/claim/saveClaimInfo', this.form).then((data) => {
                // axios.post('/claim/saveClaimIadasdnfo', this.form).then((data) => {
                this.$message({
                    message: '提交成功，请您耐心等待系统审核！',
                    type: 'success'
                })
                this.$router.push('/')
            }).catch((e) => {
            })
        },
        checkForm() {
            // 1.校验必填项
            let validForm = false;
            this.$refs.form.validate(valid => { validForm = valid })
            if (!validForm) {
                return false;
            }
            //其他的校验
            /*if(){
               return false;
            }*/
            return true;
        }
    },
    created() {
        axios.get('/claim/isClaim').then(() => {
        }).catch(err => {
            this.$message.success(err.response.data.message);
            history.go(-1)
            return
        })
        axios.get('/user/getPhone').then(resp => {
            this.form.phone = resp.data.data
        })
    },
}
export default options
</script>
