<template>
  <div class="container">
    <div class="body" v-loading="loading" element-loading-text="拼命加载中,若加载时间过长请刷新页面"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

      <div class="login">
        <el-form class="login-form" :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
          <div class="title">登录</div>
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" prefix-icon="el-icon-user" v-model="loginForm.phone">
              <el-button slot="append" @click="getcode">{{ codemsg }}</el-button>
            </el-input>
          </el-form-item>

          <el-form-item prop="code">
            <el-input placeholder="请输入短信验证码" prefix-icon="el-icon-lock" v-model="loginForm.code">
            </el-input>
          </el-form-item>

          <div class="other">
            <el-link type="primary" @click="goLogin">账号密码登录</el-link>
            <el-link type="primary" @click="goSaleLogin">商家登录</el-link>
          </div>

          <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="handleLogin" class="login-submit">
              登录
            </el-button>
          </el-form-item>
        </el-form>

        <el-button type="text" @click="index">返回首页</el-button>
      </div>
    </div>

  </div>
</template>
  
<script>
import axios from '@/api/request';
export default {
  data() {
    return {
      codeBanTime: 60,
      codemsg: '发送验证码',
      loading: true,
      passwordType: "password",
      loginForm: {
        //用户名
        phone: "",
        //密码
        code: "",
        // rememberPwd: false,
      },
      loginRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          { min: 1, message: "验证码长度最少为6位", trigger: "blur" }
        ]
      },
    }
  },
  mounted() {
    let that = this;
    setTimeout(function () {
      that.loading = false;
    }, 500);
  },
  methods: {
    goSaleLogin() {
      this.$router.push('/saleLogin')
    },
    goLogin() {
      this.$router.push('/login');
    },
    index() {
      this.$router.push('/')
    },
    handleLogin() {
      let valid_rule = /^1[3456789]\d{9}$/
      if (this.loginForm.phone.match(valid_rule)) {
        axios.post('/user/login', {
          phone: this.loginForm.phone,
          code: this.loginForm.code
        }).then((resp) => {
          localStorage.setItem('token', resp.data.data)
          this.$message({
            type: 'success',
            message: '登录成功!'
          });
          this.$router.push('/');
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: '登录失败!用户名或密码错误'
          });
        })
      } else {
        this.$message({
          type: 'error',
          message: '手机号或验证码错误！'
        });
      }
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    async getcode() {
      this.banCodeButton = true
      let valid_rule = /^1[3456789]\d{9}$/
      if (this.loginForm.phone.match(valid_rule)) {
        axios.post('/user/getCode', { phone: this.loginForm.phone, status: 1 }).then(() => {
          this.$message({
            type: 'success',
            message: '发送验证码成功!'
          })
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        // alert('成功')
        for (var i = 0; i < 60; i++) {
          this.codemsg = this.codeBanTime + '秒后可再次发送'
          await this.sleep(1000)
          this.codeBanTime--
        }
        this.codeBanTime = 60
        this.banCodeButton = false
        this.codemsg = '发送验证码'
      } else {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号!'
        })
      }
    },
    sleep(time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
  }
}
</script>  
  