<template>
    <div class="container">
       <el-divider content-position="center">商家列表</el-divider>
        <div class="infinite-list-wrapper" style="overflow:auto" v-infinite-scroll="load">
            <div infinite-scroll-disabled="disabled" style="overflow:auto;height: 100vh;">
                <el-descriptions class="margin-top" direction="vertical" :column="3" border size="mini"
                    v-for="(store, index) in storeList" :key="index">
                    <el-descriptions-item>
                        <img src="@/assets/shangjia.jpg" style="width:20vw;height:20vw"/>  
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            店铺
                        </template>
                        {{ store.username }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            手机号
                        </template>
                        {{ store.phone }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            店铺地址
                        </template>
                        {{ store.storeLocal }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            ↑人才优惠（ {{ store.username }} ）
                        </template>
                        第一类人才:{{ store.discount.firstCategory }}折
                        第二类人才:{{ store.discount.secondCategory }}折
                        第三类人才:{{ store.discount.thirdCategory }}折
                        第四类人才:{{ store.discount.fourthCategory }}折
                        第五类人才:{{ store.discount.fifthCategory }}折
                        第六类人才:{{ store.discount.sixthCategory }}折
                    </el-descriptions-item>
                </el-descriptions>
                <p v-if="max">加载中...</p>
                <p v-if="!max">没有更多了</p>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '@/api/request'
export default {
    data() {
        return {
            storeList: [],
            page: 1,
            pageSize: 5,
            max: true
        }
    },
    created() {
        this.load()
    },
    computed: {
        noMore() {
            return this.max
        },
        disabled() {
            return this.noMore
        }
    },
    methods: {
        load() {
            if(!this.max){
                return
            }
            axios.get('/store/getStorePage?page=' + this.page + '&pageSize=' + this.pageSize).then(resp => {
                this.page++
                var list = resp.data.data.records
                if (list.length == 0) {
                    this.max = false
                }
                for (let i = 0; i < list.length; i++) {
                    this.storeList.push(list[i])
                }
                this.storeList.push()
            })
        }
    }
}
</script>
<style scoped>

</style>