import tabbar from './tabbar.vue'
import navbar from './navbar.vue'
import storeNav from './storeNav.vue'

export default {
  install: function (Vue) {
    Vue.component('tabbar', tabbar)
    Vue.component('navbar', navbar)
    Vue.component('storeNav', storeNav)
  }
}