<template>
    <div class="container">
        <navbar :title="proName" />
        <div class="body">
            <div class="subtitle" center>填写申报信息</div>
            <el-form :model="form" label-width="80px" :rules="rules" ref="form">
                <el-form-item style="margin-left:-20vw">
                    请输入子女姓名 <el-input placeholder="请输入子女姓名" v-model="form.cName" />
                    请输入子女身份证号 <el-input placeholder="请输入子女身份证号" v-model="form.cAge" />
                </el-form-item>
                <el-divider content-position="center">材料上传</el-divider>
                <div class="subtitle">上传压缩包</div>
                <el-upload class="avatar-uploader" ref="otherLicense" action :auto-upload="false"
                    :on-preview="handlePicPreview" :on-remove="handleRemove" :file-list="fileList"
                    :on-change="otherSectionFile" list-type="picture-card" :limit="1">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" class="avatar">
                </el-dialog>
                <el-checkbox v-model="form.checked" style="margin: 30px;">本人承诺上述信息填写真实有效</el-checkbox>
                <el-form-item>
                    <el-button type="primary" @click="submit">保存并提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import axios from "@/api/request"

const options = {
    data() {
        return {
            codeBanTime: 10,
            msg: '点击复制链接并生成验证码',
            code: '',
            pcURL:'http://47.113.228.1:7080/#/childrenGoShoolPcVue?id='+this.$route.query.id,
            dialogImageUrl: '',
            dialogVisible: false,
            id: '',
            proName: '',
            form: {
                cName: '',
                cAge: '',
                checked: false,
                zip: '',
            }
        }
    },
    created() {
        this.id = this.$route.query.id
        this.proName = this.$route.query.projectName
    },
    methods: {
        sleep(time) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, time)
            })
        },
        // 复制功能
        async copy(data) {
            this.ban = true
            axios.get('/project/pcGetCode?id=' + this.id).then(res => {
                this.code = res.data.data
            })
            // 存储传递过来的数据
            let OrderNumber = data;
            // 创建一个input 元素
            // createElement() 方法通过指定名称创建一个元素
            let newInput = document.createElement("input");
            // 讲存储的数据赋值给input的value值
            newInput.value = OrderNumber;
            // appendChild() 方法向节点添加最后一个子节点。
            document.body.appendChild(newInput);
            // 选中input元素中的文本
            // select() 方法用于选择该元素中的文本。
            newInput.select();
            // 执行浏览器复制命令
            //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
            document.execCommand("Copy");
            // 清空输入框
            newInput.remove();
            // 下面是element的弹窗 不需要的自行删除就好
            this.$message({
                message: "复制成功",
                type: "success",
            });
            for (var i = 0; i < 10; i++) {
                this.msg = this.codeBanTime + '秒后可再次生成验证码'
                await this.sleep(1000)
                this.codeBanTime--
            }
            this.codeBanTime = 10
            this.ban = false
            this.msg = '点击复制链接并生成验证码'
        },

        submit() {
            if (!this.form.checked) {
                this.$message.info('请勾选承诺');
                return
            }
            if (this.form.zip === '') {
                this.$message.info('请上传文件');
                return
            }
            axios.post('/applySchool/saveChildrenInfo', {
                projectId: this.id,
                projectName: this.proName,
                filePath: this.form.zip,
                childNum: this.form.cAge,
                childName: this.form.cName
            }).then(() => {
                this.$message.success('申请成功');
                history.go(-1)
            }).catch(err => {
                this.$message.error(err.response.data.message);
            })
        },
        //图片上传
        handlePicPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleRemove(file, fileList) {
            this.fileList.map((item, index) => {
                if (item.uid === file.uid) {
                    this.fileList.splice(index, 1)
                }
            })
            var url = this.fileDelUrlMap.get(file.raw.uid)
            this.$axios.get(url)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        otherSectionFile(file) {
            this.otherFiles = file.raw;
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access_token': 'bT4vhYOyshOvX7QWsZU35v:Qp1tKsyEZtUQIwcYf1ZIlF339jKMNYt:1',
                    'accessKeyId': 'b461c3a11985eb76',
                    'accessKeySecret': 'd66fc04bb4bbec19c7e3'
                },
                methods: 'post'
            }
            this.$axios.post("/api1/ajax/bucket/create", {
                file: this.otherFiles,
                bucket: 'bu1',
                filePath: '/zzb_project'
            }, config).then(res => {
                // this.fileList.push(file)
                if (res.status === 200) {
                    this.form.zip = res.data.data[0].url
                    this.$message.success('上传文件成功')
                } else {
                    this.$message.error(res.data.statusText)
                }
            })
        },
    },
}
export default options
</script>
<style scoped>

</style>