<template>
    <el-button type="text" @click="open">申领文旅卡激活码</el-button>
  </template>
  
  <script>
  
  import axios from "axios";
  
  export default {
    name: "File-",
    methods: {
      open() {
        axios.post('api/tour/status').then(res=>{
          if(res.data == ''){
            this.$alert('这是一段内容', '标题名称', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message: `action: ${ action }`
                });
              }
            });
          }else{
            this.$alert('这是一段内容', '标题名称', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message: `action: ${ action }`
                });
              }
            });
          }
        })
  
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  <style>
  .el-tree-node__label {
    font-size: 19px;
  }
  </style>