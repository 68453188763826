<template>
    <div class="container">
        <navbar title="详情" />
        <div class="body detail">
            <h1>{{ news.projectName }}</h1>
            <div class="info">
            开始时间：    {{ news.startTime }} <br/>
            结束时间：    {{ news.endTime }}
            </div>
            <div class="content" v-html="news.html"></div>
            <el-backtop></el-backtop>
        </div>
        <el-button @click="goPublish">申请入学</el-button>
    </div>
</template>
<script>
import axios from '@/api/request'
const options = {
    data() {
        return {
            id:'',
            proName:'',
            news: ''
        }
    },
    methods: {
        goPublish(){
            axios.get('/applySchool/isClaim?id='+this.id).then(()=>{
                this.$router.push({ path: '/childrenPageVue', query: { id: this.id, projectName: this.proName } })
            }).catch(err=>{
                this.$message.error(err.response.data.message)
            })
        },
        goback() {
            history.back()
        }
    },
    created() {
         this.id = this.$route.query.id
         this.proName = this.$route.query.projectName
        axios.get('/applySchool/getApplyInfo', {
            params: {
                page: 1,
                pageSize: 2,
                id: this.id
            }
        }).then((resp) => {
            this.news = resp.data.data
        }).catch()
    },
}
export default options
</script>