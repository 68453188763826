// 定义一些公共的属性和方法
const base = {
  title: '人才',
  homebg: 'homebg1.jpg'
}


// 菜单栏
const tabbar = [
  { title: '首页', path: '/', icon: 'home.png', icons: 'homes.png' },
  { title: '进度', path: '/stepsMenu', icon: 'speed.png', icons: 'speeds.png' },
  { title: '我的', path: '/my', icon: 'my.png', icons: 'mys.png' }
]

const menu = [
  { id: 1, title: '人才码申请', path: '/edit', icon: 'code.png' },
  { id: 2, title: '文旅服务', path: '', icon: 'trip.png' },
  { id: 3, title: '项目申报', path: '/declaration', icon: 'project.png' },
  { id: 4, title: '优惠商家', path: '/stores', icon: 'store.png' },
  { id: 5, title: '子女入学', path: '/childrenGoScoolListVue', icon: 'school.png' },
  { id: 6, title: '法律服务', path: '', icon: 'law.png' },
  { id: 7, title: '招聘资讯', path: '', icon: 'job.png' },
  { id: 8, title: '人才码互通', path: '', icon: 'codes.png' },
]

// 暴露出这些属性
export default {
  base,
  tabbar,
  menu
}