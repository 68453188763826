<template>
    <div class="container">
        <navbar title="项目申报审核进度" />
        <div class="body" v-loading="loading" element-loading-text="正在查询中,若加载时间过长请刷新页面"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

            <el-result icon="info" title="暂无进度" subTitle="请您耐心等待审核，谢谢！" v-if="items.length == 0">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="goIndex">返回</el-button>
                </template>
            </el-result>

            <div class="stepsList" v-else>
                <ul>
                    <li v-for="item in items" :key="item.id">
                        <h4 v-if="item.status == 0">待审核</h4>
                        <h4 v-if="item.status == 1">已通过</h4>
                        <h4 v-if="item.status == 2">已驳回</h4>
                        <p v-if="item.status == 2" class="content red">驳回意见：{{ item.result }}</p>
                        <p v-else class="content green">{{ item.result }}</p>
                        <p class="datetime">更新于 {{ item.modiTime }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '@/api/request'
const options = {
    data() {
        return {
            loading: true,
            items: []
        }
    },
    methods: {
        goIndex() {
            history.go(-1)
        }
    },
    mounted() {
        let that = this;
        setTimeout(function () {
            that.loading = false;
        }, 700);
    },
    created() {
        var res = []
        axios.get('/user/getStatus?classify=' + this.$route.query.classify).then(resp => {
            this.items = resp.data.data;
        }).catch(e => {
            this.$alert('出错了，请刷新重试 （可能未登录）', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    history.go(-1)
                }
            });
        })

    },
}
export default options
</script>
<style scoped>

</style>