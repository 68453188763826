<template>
    <div class="container">
        <navbar title="商家类型" />
        <div id="out">
            <div class="in" @click="storeType('1','美食')">
                <img src="@/assets/storeTypes/jiuba.png">
                <p>美食</p>
            </div>
            <div class="in" @click="storeType('2','电影演出')">
                <img src="@/assets/storeTypes/jsyd.png">
                <p>电影演出</p>
            </div>
            <div class="in" @click="storeType('3','休闲玩乐')">
                <img src="@/assets/storeTypes/KTV.png">
                <p>休闲玩乐</p>
            </div>
            <div class="in" @click="storeType('4','酒店民宿')">
                <img src="@/assets/storeTypes/ms.png">
                <p>酒店民宿</p>
            </div>
            <div class="in" @click="storeType('5','生活服务')">
                <img src="@/assets/storeTypes/spa.png">
                <p>生活服务</p>
            </div>
        </div>
        <storesVue></storesVue>
    </div>
</template>
<script>
import storesVue from '@/views/storeInner.vue'
const options = {
components: { storesVue },
    data() {
        return {

        }
    },
    methods: {
        goback() {
            history.go(-1)
        },
        storeType(classify,type){
            this.$router.push({ path: '/stores', query: { classify: classify, type: type} })
        },
    },
}
export default options
</script>
<style scoped>
img {
    height: 15vw;
    width: 15vw;
}

#out {
    text-align: center;
    display: flex;
}

.in {
    margin-left: 17px;
    text-align: center;
    display: inline-block;
}
</style>