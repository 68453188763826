<template>
    <div class="container">
        <div class="body user">
            <div class="header" :style="{ backgroundImage: 'url(' + require('@/assets/image/12shop.png') + ')' }"></div>
            <div class="userBox">
                <div class="quitLogin" @click="saoma">
                    点击扫码
                </div>
                <div class="userInfo">
                    <h2>{{ saleInfo.username }}</h2>
                    <span>电话：{{ saleInfo.phone }}</span>
                    <img src="@/assets/image/12shop.png" class="userLogo" />
                </div>
                <div class="userDesc">
                    {{ saleInfo.storeDescribe }}
                </div>
            </div>

            <div class="userSale">
                <h5>店铺折扣信息</h5>
                {{ this.countText }}
            </div>

            <div class="userOrder" v-if="order[0]">
                <h5>订单记录</h5>
                <div class="dishList">
                    <!-- <div v-for="(item, index) in records" :key="index" class="records" style="font-size:15px;text-align:left">
                        {{ item.name }} {{ item.createTime }}{{ item.endTime }}
                    </div> -->
                    <el-table :data="records">
                        <el-table-column prop="id" label="订单号" width="90">
                        </el-table-column>
                        <el-table-column prop="price" label="原价" width="90">
                        </el-table-column>
                        <el-table-column prop="endPrice" label="折后价" width="90">
                        </el-table-column>
                        <el-table-column prop="createTime" label="交易时间" width="90">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/static/js/base';
import '@/static/js/vant.min';
import axios from '@/api/request';
const options = {
    data() {
        return {
            countText: '',
            records: [],
            discounts: {},
            saleInfo: {},
            form: {
                phone: '',
                code: ''
            },
            msgFlag: false,
            order: [{
                orderTime: '',//下单时间
                status: undefined,//订单状态 1已结账，2未结账，3已退单，4已完成，5已取消
                orderDetails: [{
                    name: '',//菜品名称
                    number: undefined,//数量
                }],//明细
                amount: undefined,//实收金额
                sumNum: 0,//菜品总数
            }],
            headerImg: require('@/assets/image/12shop.png')
        }
    },
    created() {
        axios.get('/store/getStoreInfo').then(resp => {
            this.saleInfo = resp.data.data
        })
        axios.get('/store/getRebInfo').then(resp => {
            this.records = resp.data.data
        })
        axios.get('/store/getDiscount').then(resp => {
            this.discounts = resp.data.data
            this.countText = `第一类人才:${this.discounts.firstCategory}折
                        第二类人才:${this.discounts.secondCategory}折
                        第三类人才:${this.discounts.thirdCategory}折
                        第四类人才:${this.discounts.fourthCategory}折
                        第五类人才:${this.discounts.fifthCategory}折
                        第六类人才:${this.discounts.sixthCategory}折`
        })
    },
    methods: {
        goBack() {
            history.go(-1)
        },
        toAddressPage() {
            window.requestAnimationFrame(() => {
                window.location.href = '/front/page/address.html'
            })
        },
        toOrderPage() {
            window.requestAnimationFrame(() => {
                window.location.href = '/front/page/order.html'
            })
        },
        initData() {
            this.getLatestOrder()
        },
        async getLatestOrder() {
            const params = {
                page: 1,
                pageSize: 1
            }
            const res = await orderPagingApi(params)
            if (res.code === 1) {
                this.order = res.data.records
                if (this.order && this.order[0].orderDetails) {
                    let number = 0
                    this.order[0].orderDetails.forEach(item => {
                        number += item.number
                    })
                    this.order[0].sumNum = number
                }
            } else {
                this.$notify({ type: 'warning', message: res.msg });
            }
        },
        getStatus(status) {
            let str = ''
            switch (status) {
                case 1:
                    str = '待付款'
                    break;
                case 2:
                    str = '正在派送'
                    break;
                case 3:
                    str = '已派送'
                    break;
                case 4:
                    str = '已完成'
                    break;
                case 5:
                    str = '已取消'
                    break;

            }
            return str
        },
        async addOrderAgain() {
            const res = await orderAgainApi({ id: this.order[0].id })
            if (res.code === 1) {
                window.requestAnimationFrame(() => {
                    window.location.href = '/front/index.html'
                })
            } else {
                this.$notify({ type: 'warning', message: res.msg });
            }
        },
        saoma() {
            let token = localStorage.getItem('token')
            window.location.href = 'https://ca.xiangwangsoft.cn/#/?token=' + token;
            // window.location.href = 'http://106.227.6.9:7080?token=' + token;
            //eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzM3MDY4NjUsInVzZXJJZCI6IjEzNDc5NDU4NzU4IiwiaWF0IjoxNjcyODE3ODMzfQ.eudnzUx1zf98us5pmVz_X8IVX_hnfrzMJ0OtEPjN4g8
        }
    }
}
export default options
</script>