import axios from "axios";
const _axios = axios.create({
    baseURL: '/api'
    // baseURL: 'http://192.168.0.14:8000'
})
_axios.interceptors.request.use(
    function(config){
        var token = localStorage.getItem('token')
        config.headers={
            Authorization:token
        }
        return config
    }
)
export default _axios