<template>
    <div class="container">
        <navbar title="新闻资讯" />
        <div class="infinite-list-wrapper" v-infinite-scroll="load" style="overflow:auto;height: 100vh;">
            <div class="news" infinite-scroll-disabled="disabled">
                <ul>
                    <li v-for="news in newsList" :key="news.id" @click="goNews(news.id)">
                        {{ news.title }}
                        <p>
                            <span>{{ news.subtitle }}</span>
                            <span>{{ news.createDate }}</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="more" v-if="max">加载中...</div>
            <div class="more" v-if="!max">没有更多了</div>
        </div>
    </div>
</template>
<script>
import axios from '@/api/request'
const options = {
    data() {
        return {
            newsList: [],
            page: 1,
            pageSize: 10,
            max: true,
        }
    },
    methods: {
        load() {
            if (!this.max) {
                return
            }
            this.page++
            axios.get('/news/getNewsPage?page=' + this.page + '&pageSize=' + this.pageSize + '&status=1').then(resp => {
                var list = resp.data.data.records
                if (list.length === 0) {
                    this.max = false
                }
                for (let i = 0; i < list.length; i++) {
                    this.newsList.push(list[i])
                }
            })
        },
        goNews(id) {
            this.$router.push({ path: '/news', query: { id: id } })
        },
        goIndex() {
            this.$router.push('/');
        }
    },
    created() {
        axios.get('/news/getNewsPage', {
            params: { page: 1, pageSize: 10, status: 1 }
        }).then(res => {
            this.newsList = res.data.data.records
        }).catch(res => {
            this.$message.error({
                message: '服务器出错，请刷新重试',
                center: true
            })
        })
    },
}
export default options
</script>