import Vue from 'vue'
import VueRouter from 'vue-router'
import edit from '@/views/Edit.vue'
import index from '@/views/Index.vue'
import login from '@/views/Login.vue'
import register from '@/views/Register.vue'
import login2 from '@/views/LoginByCode.vue'
import user from '@/views/userPage.vue'
import talentStep from '@/views/talentStep.vue'
import projectStep from '@/views/projectStep.vue'
import goSchoolStep from '@/views/goSchoolStep.vue'
import newsPage from '@/views/newsPage.vue'
import newsList from '@/views/newsList.vue'
import my from '@/views/my.vue'
import saleLogin from '@/views/saleLogin.vue'
import stepsMenu from '@/views/stepsMemu.vue'
import stores from '@/views/stores.vue'
import storeType from '@/views/storeType.vue'
import declaration from '@/views/declaration.vue'
import realDeclaration from '@/views/realDeclaration.vue'
import publishZip from '@/views/publishZip.vue'
import jihuomaVue from '@/views/jihuoma.vue'
import childrenPageVue from '@/views/childrenPage.vue'
import childrenGoScoolListVue from '@/views/childrenGoScoolList.vue'
import realChildren from '@/views/realChildrengoSchool.vue'
import orderListVue from '@/views/orderList.vue'

Vue.use(VueRouter)

// 解决回调报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/edit',
    name: 'edit',
    component: edit,
    meta: {
      auth: true//添加字段判断该页面是否需要登录
    }
  },
  {
    path: '/declaration',
    name: 'declaration',
    component: declaration
  },
  {
    path: '/orderListVue',
    name: 'orderListVue',
    component: orderListVue
  },
  {
    path: '/realChildren',
    name: 'realChildren',
    component: realChildren
  },
  {
    path: '/childrenGoScoolListVue',
    name: 'childrenGoScoolListVue',
    component: childrenGoScoolListVue
  },
  {
    path: '/publishZip',
    name: 'publishZip',
    component: publishZip
  },
  {
    path: '/childrenPageVue',
    name: 'childrenPageVue',
    component: childrenPageVue
  },
  {
    path: '/jihuomaVue',
    name: 'jihuomaVue',
    component: jihuomaVue
  },
  {
    path: '/realDeclaration',
    name: 'realDeclaration',
    component: realDeclaration
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/storeType',
    name: 'storeType',
    component: storeType
  },
  {
    path: '/stores',
    name: 'stores',
    component: stores
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/loginByCode',
    name: 'register',
    component: login2
  },
  {
    path: '/stores',
    name: 'stores',
    component: stores
  },
  {
    path: '/talentStep',
    name: 'talentStep',
    component: talentStep,
    meta: {
      auth: true//添加字段判断该页面是否需要登录
    }
  },
  {
    path: '/stepsMenu',
    name: 'stepsMenu',
    component: stepsMenu,
    meta: {
      auth: true//添加字段判断该页面是否需要登录
    }
  },
  {
    path: '/projectStep',
    name: 'projectStep',
    component: projectStep,
    meta: {
      auth: true//添加字段判断该页面是否需要登录
    }
  },
  {
    path: '/goSchoolStep',
    name: 'goSchoolStep',
    component: goSchoolStep,
    meta: {
      auth: true//添加字段判断该页面是否需要登录
    }
  },
  {
    path: '/user',
    name: 'user',
    component: user
  },
  {
    path: '/news',
    name: 'newsPage',
    component: newsPage
  },
  {
    path: '/saleLogin',
    name: 'saleLogin',
    component: saleLogin
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: newsList
  },
  {
    path: '/my',
    name: 'my',
    component: my
  },
  {
    path: '/',
    name: 'index',
    component: index
  }
]

const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 在localStorage中获取token
  let token = localStorage.getItem('token')
  // 判断该页面是否需要登录
  if (to.meta.auth) {
    // 如果token存在直接跳转
    if (token) {
      next()
    } else {
      // 否则跳转到login登录页面
      next({
        path: '/login',
        // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    // 如果不需要登录，则直接跳转到对应页面
    next()
  }
})

export default router
