<template>
  <ul class="tabbar">
    <li v-for="(item, index) in menuData" :key="index" :class="item.path === path ? 'selected' : ''"
      @click="itemClick(item.path)">
      <img :src="require('@/static/img/tabbar/' + item.icons)" v-if="item.path === path" />
      <img :src="require('@/static/img/tabbar/' + item.icon)" v-else />
      <p>{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      path: '/',
      menuData: this.global.tabbar
    }
  },
  created() {
    this.path = this.$route.path
  },
  methods: {
    itemClick(e) {
      //用push还是replace看项目要求
      this.path = e
      this.$router.replace(e).catch((err) => err);
    }
  }
}
</script>