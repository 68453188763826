<template>
    <div class="container">
        <navbar :title="proName" />
        <div class="body">
            <div class="subtitle">填写申报信息</div>
            <el-form :model="form" ref="form" :rules="formRule">
                <el-form-item label="项目名" prop="pName">
                    <el-input placeholder="请输入项目名" v-model="form.pName" />
                </el-form-item>
                <el-divider content-position="center">材料上传</el-divider>
                <el-form-item label="上传文件" prop="zip">
                    <el-upload :before-remove="beforeRemove" :on-change="otherSectionFile" action="#"
                               :auto-upload="false" :limit="1" :on-exceed="handleExceed">
                        <el-button size="small" type="primary">点击选择文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="form.checked">本人承诺上述信息填写真实有效</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button class="middle" type="primary" @click="submit">保存并提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import axios from "@/api/request"

const options = {
    data() {
        return {
            ban: false,
            code: '',
            dialogImageUrl: '',
            id: '',
            proName: '',
            form: {
                pName: '',
                checked: false,
                zip: '',
            },
            formRule: {
                pName: [{ required: true, message: '请输入项目名' }],
                zip: [ {required: true, message: '请上传文件' }]
            }
        }
    },
    created() {
        this.id = this.$route.query.id
        this.proName = this.$route.query.projectName
    },
    methods: {
        submit() {
            if (!this.form.checked) {
                this.$message.info('请勾选承诺')
                return
            }
            if (this.form.zip === '') {
                this.$message.info('请上传文件')
                return
            }
            axios.post('/project/saveProcessInfo', {
                projectId: this.id,
                proName: this.form.pName,
                filePath: this.form.zip
            }).then(() => {
                this.$message.success('上传成功')
                history.go(-1)
            }).catch(() => {
                this.$message.error('上传失败，请重试')
            })
        },
        handleRemove(fileList) {
            this.form.zip = ''
            fileList.splice(0, 1)
        },
        beforeRemove(file, fileList) {
            this.$confirm(`确定删除文件"${file.name}"吗？`).then(() => {
                this.handleRemove(fileList)
            }).catch(() => {})
            return false
        },
        handleExceed() {
            this.$message.warning('只能上传一个文件')
        },
        otherSectionFile(file, fileList) {
            this.otherFiles = file.raw
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access_token': 'bT4vhYOyshOvX7QWsZU35v:Qp1tKsyEZtUQIwcYf1ZIlF339jKMNYt:1',
                    'accessKeyId': 'b461c3a11985eb76',
                    'accessKeySecret': 'd66fc04bb4bbec19c7e3'
                },
                methods: 'post'
            }
            this.$axios.post("/api1/ajax/bucket/create", {
                file: this.otherFiles,
                bucket: 'bu1',
                filePath: '/zzb_project'
            }, config).then(res => {
                if (res.status === 200) {
                    this.form.zip = res.data.data[0].url
                    this.$message.success('上传文件成功')
                } else {
                    this.$message.error(res.data.statusText)
                }
            }).catch(() => {
                this.handleRemove(fileList)
                this.$message.error('上传文件失败，请重试')
            })
        },
    },
}
export default options
</script>
<style>
.el-form {
    padding: 1rem !important;
}

.el-form .middle {
    display: block;
    margin: 0 auto;
}
</style>