<template>
    <div class="container">
        <div class="body stepsMenu">
            <ul class="card">
                <li>
                    <router-link :to="{path:'/talentStep' , query :{classify:'1'}}">
                        人才码审核进度
                    </router-link>
                </li>
                <li>
                    <router-link :to="{path:'/projectStep' , query :{classify:'2'}}">
                        项目申报审核进度
                    </router-link>
                </li>
                <li>
                    <router-link :to="{path:'/goSchoolStep' , query :{classify:'3'}}">
                        子女入学审核进度
                    </router-link>
                </li>
            </ul>
        </div>
        <tabbar></tabbar>
    </div>
</template>
<script>
const options = {
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        let that = this;
        setTimeout(function () {
            that.loading = false;
        }, 700);
    },
    methods: {
        goback() {
            history.go(-1)
        }
    },
}
export default options
</script>