<template>
  <div class="navbar">
    <el-button class="back" icon="el-icon-arrow-left" @click="back"></el-button>
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    to: {
      type: Function,
      default: null
    }
  },
  methods: {
    back() {
      if (this.to) {
        this.to()
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>