<template>
    <div class="container">
        <div class="body my">

            <div class="header">
                <img src="@/static/img/head.jpg" />
                <div class="userInfo" v-if="userInfo.name">
                    {{ userInfo.name || '登录' }}
                    <p>优惠余额{{ userInfo.limitPrice }}</p>
                </div>
                <div class="userInfo" v-else @click="goLogin">
                    请先登录
                </div>
            </div>

            <ul class="list">
                <li @click="toAddressPage">
                    订单管理
                    <img src="@/static/img/right.png" class="right" />
                </li>
                <li @click="toOrderPage">
                    地址管理
                    <img src="@/static/img/right.png" class="right" />
                </li>
            </ul>

            <div class="out" @click="open" v-if="userInfo.name">退出登录</div>

        </div>
        <tabbar></tabbar>
    </div>
</template>
<script>
import '@/static/js/base';
import '@/static/js/vant.min';
import axios from '@/api/request';
const options = {
    data() {
        return {
            userInfo: {},
            form: {
                phone: '',
                code: ''
            },
            msgFlag: false,
            order: [{
                orderTime: '',//下单时间
                status: undefined,//订单状态 1已结账，2未结账，3已退单，4已完成，5已取消
                orderDetails: [{
                    name: '',//菜品名称
                    number: undefined,//数量
                }],//明细
                amount: undefined,//实收金额
                sumNum: 0,//菜品总数
            }],
        }
    },
    created() {
        axios.get('/user/getUserInfo').then(resp => {
            this.userInfo = resp.data.data
        })
    },
    methods: {
        goLogin() {
            let token = localStorage.getItem("token")
            if (token == null) {
                this.$router.push({ path: '/login' })
            }
        },
        open() {
            this.$confirm('确认退出登录?', '提示', {
                confirmButtonText: '确定',
                center: true,
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '成功退出登录!'
                });
                this.logout()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        goBack() {
            history.go(-1)
        },
        toAddressPage() {
            window.requestAnimationFrame(() => {
                this.$router.push('/orderListVue')
            })
        },
        toOrderPage() {
            window.requestAnimationFrame(() => {
                window.location.href = '/front/page/order.html'
            })
        },
        initData() {
            this.getLatestOrder()
        },
        async getLatestOrder() {
            const params = {
                page: 1,
                pageSize: 1
            }
            const res = await orderPagingApi(params)
            if (res.code === 1) {
                this.order = res.data.records
                if (this.order && this.order[0].orderDetails) {
                    let number = 0
                    this.order[0].orderDetails.forEach(item => {
                        number += item.number
                    })
                    this.order[0].sumNum = number
                }
            } else {
                this.$notify({ type: 'warning', message: res.msg });
            }
        },
        getStatus(status) {
            let str = ''
            switch (status) {
                case 1:
                    str = '待付款'
                    break;
                case 2:
                    str = '正在派送'
                    break;
                case 3:
                    str = '已派送'
                    break;
                case 4:
                    str = '已完成'
                    break;
                case 5:
                    str = '已取消'
                    break;

            }
            return str
        },
        logout() {
            localStorage.removeItem("token")
            this.$router.push('/')
        }
    }
}
export default options
</script>