<template>
    <div class="container">
        <div class="body home">
            <div class="header"
                :style="{ background: 'no-repeat center top/cover url(' + require('@/static/img/' + homebg) + ')' }">
                <button class="QRcode" @click="getCode">人才码</button>
            </div>
            <div class="submenu">
                <ul>
                    <li v-for="(item, index) in menuData" :key="index" @click="itemClick(item)">
                        <img :src="require('@/static/img/' + item.icon)" />
                        <p>{{ item.title }}</p>
                    </li>
                </ul>
            </div>

            <div class="news">
                <div class="title" @click="goNewsList">
                    最新资讯
                    <img src="@/static/img/right.png" class="right" />
                </div>
                <ul>
                    <li v-for="news in newsList" :key="news.id" @click="goNews(news.id)">
                        {{ news.title }}
                        <p>
                            <span>{{ news.subtitle }}</span>
                            <span>{{ news.createDate }}</span>
                        </p>
                    </li>
                </ul>
            </div>
            <el-dialog :visible.sync="visible" :title="userInfo.name">
                <img :src="talentPic" v-if="visible" style="width: 100%; height: 100%">
                <!-- <p>hello world</p> -->
            </el-dialog>
        </div>
        <tabbar></tabbar>
    </div>
</template>
<script>
import axios from "@/api/request";
const options = {
    data() {
        return {
            userInfo: {},
            visible: false,
            newsList: [],
            talentPic: '',
            posted: 0,
            menuData: this.global.menu,
            homebg: this.global.base.homebg
        }
    },
    mounted() {
        //在mounted 声明周期中创建定时器
        const timer = setInterval(() => {
            // 这里调用调用需要执行的方法，1为自定义的参数，由于特殊的需求它将用来区分，定时器调用和手工调用，然后执行不同的业务逻辑
            this.posted = 0
        }, 60000) // 每60秒执行1次
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除
        this.$once('hook:beforeDestroy', () => {
            // 在页面销毁时，销毁定时器
            clearInterval(timer)
        })
    },
    created() {
        axios.get('/store/getStoreInfo').then(resp => {
            if (resp.data.data.username != null) {
                this.$router.push('/user')
            }
            axios.get('/user/getUserInfo').then(resp => {
                this.userInfo = resp.data.data
            }).catch(err => {
                localStorage.removeItem('token')
            })
        }).catch(err => {
            axios.get('/user/getUserInfo').then(resp => {
                this.userInfo = resp.data.data
            }).catch(err => {
                localStorage.removeItem('token')
            })
        })
        axios.get('/news/getNewsPage', {
            params: {
                page: 1,
                pageSize: 6,
                status: 1
            }
        }).then(res => {
            this.newsList = res.data.data.records
        })
    },
    methods: {
        itemClick(e) {
            if (!e.path) this.onPending()
            if (e.id === 1) this.toEdit(e.path)
            else this.$router.push(e)
        },
        onPending() {
            this.$message({
                message: '功能待开发中，请耐心等待。',
                type: 'warning'
            });
        },
        communicate() {
            this.$alert('正在对接中', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${action}`
                    });
                }
            });
        },
        sleep(time) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, time)
            })
        },
        getCode() {
            this.visible = true
            axios.get('/user/getTalentPic').then(async res => {
                // this.talentPic = res.data.data
                var config = {
                    responseType: 'arraybuffer',
                    headers: {
                        // postman 中的请求头
                        'accessKeyId': 'AqPWaKKKVkKTCMW6xM0azM',
                        'accessKeySecret': '9lZe2J1aeqIMJFUwV0QRjNTSmi2n7bb'
                    }
                };
                // 重新获取请求，获取的是base64位的图片
                await this.$axios.get(res.data.data, config).then(response => {
                    this.talentPic = 'data:image/png;base64,' + btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                }
                )
                //等待付款请求
                if (this.posted == 0) {
                    this.posted = 1
                    axios.get('/pay/handleMoney').then(resp => {
                        this.openPay(resp.data.data)
                    }).catch((e) => {
                    })
                }

            }).catch(err => {
                if (err.response.status == 401) {
                    this.$message({
                        type: 'warning',
                        message: err.response.data.message
                    });
                    this.$router.push('/login')
                } else {
                    this.$message({
                        type: 'error',
                        message: "出现异常，请尝试刷新页面或重新登录！"
                    });
                }
            })
            // this.talentPic = talentPic
        },
        goNewsList() {
            this.$router.push('/newsList')
        },
        toEdit(url) {
            axios.get('/user/toEdit').then((resp) => {
                axios.get('/claim/isClaim').then(() => {
                    this.$router.push(url);
                }).catch(err => {
                    this.$message.success(err.response.data.message);
                })
            }).catch((e) => {
                this.$message({
                    type: 'warning',
                    message: '请先登录!'
                });
                this.$router.push('/login')
            })
        },
        goNews(id) {
            this.$router.push({ path: '/news', query: { id: id } })
        },
        goLogin() {
            let token = localStorage.getItem("token")
            if (token == null) {
                this.$router.push({ path: '/login' })
            }
        },
        openPay(sum) {
            const h = this.$createElement;
            this.$msgbox({
                title: '消息',
                message: h('p', null, [
                    h('span', null, '将付款'),
                    h('i', { style: 'color: teal' }, sum + '元')
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '执行中...';
                        axios.get('/pay/payment?sum=' + sum).then(resp => {
                        })
                        setTimeout(() => {
                            done();
                            setTimeout(() => {
                                instance.confirmButtonLoading = false;
                            }, 300);
                        }, 100);
                    } else {
                        done();
                    }
                }
            }).then(action => {
                alert("付款成功")
            });
        }
    },
}
export default options
</script>
