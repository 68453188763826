<template>
  <div class="container">
    <div class="body" v-loading="loading" element-loading-text="拼命加载中,若加载时间过长请刷新页面"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

      <div class="login">
        <el-form class="login-form" :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
          <div class="title">登录</div>
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" prefix-icon="el-icon-user" v-model="loginForm.phone">
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input :type="passwordType" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="loginForm.password">
            </el-input>
          </el-form-item>

          <div class="other">
            <el-link type="primary" @click="goLoginByCode">手机验证码登录</el-link>
            <el-link type="primary" @click="goSaleLogin">商家登录</el-link>
          </div>

          <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="handleLogin" class="login-submit">
              登录
            </el-button>
            <!-- <el-divider></el-divider> -->
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain style="width: 100%;margin-bottom: 10px;" @click="goRegister" class="">
              去注册</el-button>
          </el-form-item>
        </el-form>

        <el-button type="text" @click="index">返回首页</el-button>
      </div>

    </div>
  </div>
</template>
  
<script>
import axios from '@/api/request';
export default {
  data() {
    return {
      loading: true,
      passwordType: "password",
      loginForm: {
        //用户名
        phone: "",
        //密码
        password: "",
        // rememberPwd: false,
      },
      loginRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" }
        ]
      },
    }
  },
  created() {
    let token = localStorage.getItem("token")
    if (token != null) {
      history.back()
    }
  },
  mounted() {
    let that = this;
    setTimeout(function () {
      that.loading = false;
    }, 500);
  },
  methods: {
    goSaleLogin() {
      this.$router.push('/saleLogin')
    },
    index() {
      this.$router.push('/')
    },
    goLoginByCode() {
      this.$router.push('/loginByCode');
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    goRegister() {
      this.$router.push('/register');
    },
    handleLogin() {
      let valid_rule = /^1[3456789]\d{9}$/
      if (this.loginForm.phone.match(valid_rule)) {
        axios.post("/user/login", {
          phone: this.loginForm.phone,
          passwd: this.loginForm.password
        }).then((resp) => {
          localStorage.setItem('token', resp.data.data)
          this.$message({
            type: 'success',
            message: '登录成功!'
          });
          this.$router.push('/');
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: '登录失败!用户名或密码错误'
          });
        })
      } else {
        this.$message({
          type: 'error',
          message: '手机号或密码错误！'
        });
      }
    }
  }
}
</script>