<template>
  <div class="container">
    <div class="body" v-loading="loading" element-loading-text="拼命加载中,若加载时间过长请刷新页面"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <!-- <div class="avue-home" v-if="loading" style="z-index: 99;">
        <div class="avue-home__main">
          <div class="avue-home__title">
            努力加载中...
          </div>
        </div>
      </div> -->

      <div class="login">
        <el-form class="login-form" :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
          <div class="title">注册</div>
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone" v-model="loginForm.phone">
              <el-button slot="append" @click="getcode" :disabled="banCodeButton">{{ codemsg }}</el-button>
            </el-input>

          </el-form-item>

          <el-form-item prop="code">
            <el-input placeholder="请输入验证码" prefix-icon="el-icon-chat-dot-round" v-model="loginForm.code">
            </el-input>
          </el-form-item>

          <el-form-item prop="name">
            <el-input placeholder="请输入用户名" prefix-icon="el-icon-user" v-model="loginForm.name">
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input :type="passwordType" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="loginForm.password">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-row :span="24">
              <!-- <el-col :span="12">
                  <el-checkbox v-model="loginForm.rememberPwd">记住密码</el-checkbox>
                </el-col> -->
              <el-col :span="12">
                <el-popover placement="top-start" title="" width="200" trigger="hover" content="忘记密码请联系系统管理员">
                </el-popover>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="register" class="login-submit">
              注册
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain style="width: 100%;" @click="goLogin" class="">
              返回登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>
  
<script>
import axios from '@/api/request'
export default {
  data() {
    return {
      codemsg: '发送验证码',
      codeBanTime: 60,
      banCodeButton: false,
      loading: true,
      passwordType: "password",
      loginForm: {
        //用户名
        phone: "",
        name: '',
        //密码
        password: "",
        rememberPwd: false,
        code: ''
      },
      loginRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" }
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "验证码长度为6位", trigger: "blur" }
        ],
        name: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ]
      },
    }
  },
  mounted() {
    let that = this;
    setTimeout(function () {
      that.loading = false;
    }, 500);
  },
  methods: {
    checkForm() {
      // 1.校验必填项
      let validForm = false;
      this.$refs.loginForm.validate(valid => { validForm = valid })
      if (!validForm) {
        return false;
      }
      //其他的校验
      /*if(){
         return false;
      }*/
      return true;
    },
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    goLogin() {
      this.$router.push('/login');
    },
    async getcode() {
      let valid_rule = /^1[3456789]\d{9}$/
      if (this.loginForm.phone.match(valid_rule)
        && this.loginForm.name != null && this.loginForm.code != null
        && this.loginForm.password != null) {
        this.banCodeButton = true
        axios.post('/user/getCode', { phone: this.loginForm.phone, status: 2}).then(() => {
          this.$message({
            type: 'success',
            message: '发送验证码成功!'
          })
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        // alert('成功')
        for (var i = 0; i < 60; i++) {
          this.codemsg = this.codeBanTime + '秒后可再次发送'
          await this.sleep(1000)
          this.codeBanTime--
        }
        this.codeBanTime = 60
        this.banCodeButton = false
        this.codemsg = '发送验证码'
      } else {
        this.$message({
          type: 'error',
          message: '手机号或验证码错误！'
        });
      }
    },
    sleep(time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
    register() {
      if (!this.checkForm()) {
        this.$message.error('请完善表单相关信息！');
        return;
      }
      let valid_rule = /^1[3456789]\d{9}$/
      if (this.loginForm.phone.match(valid_rule)) {
        this.loading = true
        var user = {
          phone: this.loginForm.phone,
          passwd: this.loginForm.password,
          code: this.loginForm.code,
          name: this.loginForm.name
        }
        axios.post('/user/register', user).then((resp) => {
          this.$message({
            type: 'success',
            message: '注册成功!'
          });
          this.$router.push('/login');
          this.loading = false
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: `注册失败!${e.response.data.message}`
          });
          this.loading = false
        })
      } else {
        this.$message({
          type: 'error',
          message: '手机号或验证码错误！'
        });
      }
    }
  }
}
</script>
