import { render, staticRenderFns } from "./jihuoma.vue?vue&type=template&id=d77ec316&scoped=true"
import script from "./jihuoma.vue?vue&type=script&lang=js"
export * from "./jihuoma.vue?vue&type=script&lang=js"
import style1 from "./jihuoma.vue?vue&type=style&index=1&id=d77ec316&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d77ec316",
  null
  
)

export default component.exports